.profile {
  &-page {
    margin: auto;
    .profile-info,
    .optional-info,
    .privacy-policy,
    .pro-member-info,
    .newsletter-info,
    .sms-promotions,
    .sms-info {
      &__prefix {
        width: 10%;
        min-width: 65px;
        float: left;
        padding: 0 0 0 15px;
        height: 40px;
        line-height: 40px;
        border: 1px solid black;
        margin-right: 1%;
      }
      &__number-field {
        display: inline-block;
        width: 75%;
      }
      &__item {
        select {
          @include breakpoint($small-down) {
            width: 100%;
          }
        }
      }
    }
  }
}

.orders-list {
  .tracking_disclaimer {
    display: none;
  }
}

.favorites-page {
  .original-price {
    text-decoration: line-through;
    margin-right: 5px;
  }
  .sale-price {
    color: $color-red;
    display: inline-block;
  }
}
