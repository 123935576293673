$xxsmall-down: max-width 480px;
$color-text-gray: #f6f6f6;

.cc-paypal-message {
  text-align: right;
  padding: 1em 1em 0 0;
}

#typ#map-foreground-node {
  padding: 0px;
  z-index: 100000;
}

#map-overlay-background {
  background-color: $color-white;
  opacity: 1;
}

@mixin mapcontainer-searchbox {
  font-size: 22px;
  margin-#{$rdirection}: 2px;
  float: $ldirection;
  margin-top: 0;
}

.click_and_collect_map {
  width: 100%;
  position: fixed;
  top: 0;
  padding: 0;
  z-index: 10000;
  .close-container {
    display: none;
  }
  .overlay-content {
    height: 100%;
    width: 100%;
    position: relative;
    .map-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      .map-side-tools {
        bottom: 15%;
        right: 0.4em;
        position: absolute;
        .search-box-geolocate {
          position: relative;
          width: 1.7em;
          height: 1.7em;
          font-size: 32.9px;
          right: 0.7em;
          z-index: 1;
          overflow: hidden;
          -webkit-border-radius: 50%;
          border-radius: 50%;
          color: white;
          text-align: center;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          background: black;
          cursor: pointer;
          font-family: icons;
          @include breakpoint($small-down) {
            bottom: 0px;
          }
          span:before {
            line-height: 2.2em;
          }
        }
      }
      .search-box-toggle-tooltip {
        position: absolute;
        width: 23.9em;
        height: 5.9em;
        color: white;
        background-color: black;
        font-size: 13px;
        bottom: 4.7em;
        right: 1em;
        z-index: 1;
        padding: 11px;
        padding-right: 60px;
      }
      .collection-tooltip,
      .store-tooltip {
        display: none;
      }
      .search-box-toggle {
        width: 4em;
        height: 4em;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        position: relative;
        background-color: white;
        color: black;
        padding: 8px;
        font-size: 14px;
        border: solid 1px;
        cursor: pointer;
        right: 1.7em;
        margin-bottom: 15px;
        z-index: 1;
      }
      .address-map-search-panel {
        position: absolute;
        z-index: 1;
        left: 25em;
        border-top: 0px;
        font-size: 1.4em;
        padding: 1em 0.5em 1.4em;
        background: $color-white;
        width: 29em;
        @include breakpoint($medium-only) {
          left: 19em;
        }
        @include breakpoint($medium-down) {
          left: 15em;
          top: 4em;
        }
        @include breakpoint($small-down) {
          left: 50%;
          top: 0;
          padding: 1em 0.5em;
          margin-left: -9em;
          width: 18em;
        }
        @include breakpoint($xxsmall-down) {
          top: 2em;
        }
        .status-bar-wrapper {
          position: fixed;
          bottom: 40px;
          left: 18em;
          width: 20em;
          @include breakpoint($small-down) {
            width: 100%;
            margin: 0px auto;
            left: 0px;
            font-size: 12px;
            bottom: 30px;
          }
          .status-bar {
            .message {
              border-top: 0px;
              padding: 0.5em;
              border: 1px solid #aaa;
              box-shadow: 0px 2px 4px #333;
              .close {
                float: right;
                a {
                  text-decoration: none;
                  color: inherit;
                }
              }
            }
            .message:first-child {
              border-radius: 0px;
            }
            .message.error {
              background: #fcc;
              border-color: #a66;
            }
            .message.warning {
              background: #ffc;
              border-color: #aa6;
            }
            .message.success {
              background: #cfc;
              border-color: #6a6;
            }
            .message.information {
              background: #000;
              border-color: #000;
              color: #fff;
            }
          }
        }
        .search-box-field {
          float: left;
          width: 18em;
          font-size: 14px;
          height: 40px;
          line-height: 45px;
          margin: 0 0px 0 33px;
          text-transform: uppercase;
          @include breakpoint($small-down) {
            width: 160px;
            margin: 0;
          }
        }
        div.search-box-submit {
          background-color: $color-black;
          color: $color-white;
          height: auto;
          width: auto;
          text-align: center;
          cursor: pointer;
          float: right;
          margin-top: 11px;
          margin-left: 25px;
          padding: 8px 8px;
          font-size: 1em !important;
          font-weight: normal;
          font-style: normal;
          letter-spacing: 0;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
          &.loading {
            position: relative;
          }
          @include breakpoint($small-down) {
            margin-top: 0px;
            margin-left: 10px;
            height: 42px;
            font-size: 12px !important;
            width: 20%;
            padding: 2%;
          }
        }
        .search-box-geolocate {
          font-size: 22px;
          margin-right: 2px;
          float: left;
          width: 50px;
          background: $color-white;
          position: relative;
          float: left;
          height: 42px;
          line-height: 42px;
          @include breakpoint($small-down) {
            top: 0px;
          }
          .icon--location {
            color: $color-black;
          }
          span:before {
            font-size: 20px;
          }
        }
        .search-box-toggle {
          display: none; /* not shown on designs */
          width: 50px;
          padding: 6px;
          margin-left: 0px;
          height: 30px;
          width: 30px;
          text-align: center;
          cursor: pointer;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box-geolocate {
          width: 50px;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box-cancel {
          width: 50px;
          @include mapcontainer-searchbox;
          .loading {
            span:before {
              content: '';
            }
          }
        }
        .search-box {
          overflow: hidden;
          width: 25em;
          float: left;
          @include breakpoint($small-down) {
            width: 265px;
          }
          label {
            display: block;
            font-size: inherit;
            margin: 0 0 1em;
            font-weight: bold;
          }
        }
        .search-box-geolocate {
          @include mapcontainer-searchbox;
        }
      }
      .search-box-cancel {
        padding-top: 0.1em;
        height: 44px;
        line-height: 44px;
        width: 2.5em;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 1em;
        right: 1em;
        font-size: 24px;
        font-family: icons;
        z-index: 1;
        background: $color-white;
        color: $color-black;
        @include breakpoint($small-down) {
          right: 0;
          top: 0;
        }
      }
      .search-box-geolocate {
        background: $color-black;
        color: $color-white;
        position: absolute;
        width: 50px;
        height: 50px;
        line-height: 50px;
        right: 1.3em;
        bottom: 5%;
        z-index: 1;
        padding-right: 2px;
        font-family: icons;
        font-size: 24px;
        overflow: hidden;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        text-align: center;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        cursor: pointer;
        @include breakpoint($small-down) {
          bottom: 35px;
          right: 5px;
        }
      }
      .address-map-directions-panel {
        background-color: $color-white;
        header {
          @include breakpoint($small-down) {
            width: 100%;
          }
          h2 {
            font-size: 17px;
            padding-left: 0.5em;
            float: left;
            margin-right: 4px;
          }
          a.close {
            span:before {
              display: block;
              font-family: 'icomoon';
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              font-size: 16px;
              color: $color-white;
              text-decoration: none;
            }
          }
          .button {
            float: left;
            padding: 0 3px 0 3px;
            font-size: 10px;
            border: 0;
            line-height: 23px;
            .select-access-point_dir {
              background: #ff4661;
            }
          }
        }
        a.close {
          display: block;
          position: absolute;
          right: 6px;
          top: 16px;
          text-decoration: none;
          z-index: 3;
          margin: 0 30px 0 0;
          width: 13px;
          height: auto;
          text-indent: 0px;
          font-size: 45px;
          @include breakpoint($small-down) {
            font-size: 45px;
            right: 25px;
          }
          span {
            display: none;
          }
        }
        .directions {
          float: left;
          height: 95.5%;
          .adp {
            font-size: 11px;
            margin-bottom: 50px;
          }
          #adp-placemark {
            font-size: 11px;
          }
          .adp-placemark {
            font-size: 11px;
            background-color: $color-white;
            td {
              padding: 2px 0px 2px 15px;
              color: $color-black;
            }
            td:first-child {
              width: 50px;
            }
          }
          .adp-text {
            font-size: 11px;
          }
          .adp-summary {
            font-size: 11px;
            span:nth-child(1) {
              text-align: right;
              padding-right: 10px;
              float: right;
            }
            span:nth-child(3) {
              text-transform: capitalize;
              word-wrap: break-word;
              padding-left: 50px;
            }
            span:nth-child(2) {
              display: none;
            }
          }
          .adp-step {
            padding: 0.3em 3px;
            line-height: 1.26em;
            font-size: 11px;
          }
          .adp-substep {
            padding: 0.3em 3px;
            line-height: 1.26em;
            font-size: 11px;
          }
          .adp-directions {
            tr {
              td {
                .adp-distance {
                  padding-right: 10px;
                }
                &:nth-child(1) {
                  width: 10%;
                }
                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 62%;
                  word-wrap: break-word;
                }
              }
            }
          }
        }
        .locations {
          height: 93.5%;
          overflow-y: scroll;
          background-color: $color-white;
          font-family: $font--avenir-medium;
        }
        .location {
          cursor: pointer;
          overflow: hidden;
          padding: 1em 0.5em;
          margin: 0;
          border-bottom: 1px solid $color-bg;
          -webkit-transition: padding 0.5s, background 0.5s;
          transition: padding 0.5s, background 0.5s;
          text-transform: uppercase;
          p {
            margin: 0 0 0.5em;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            img {
              width: 100%;
            }
          }
          .distance {
            float: right;
            color: $color-text-gray;
            .uom {
              text-transform: lowercase;
            }
          }
          .title {
            span {
              color: $color-black;
              text-transform: uppercase;
              font-size: 0.8rem;
              border: 0;
              padding: 0.5em 0;
              margin-bottom: 10px;
            }
          }
          .button {
            margin-top: 1em;
            border-width: 0 !important;
            box-shadow: none;
            background: none;
            width: 100%;
            padding: 0;
          }
        }
      }
      .address-map-location-panel {
        display: block;
        background-color: $color-white;
        header {
          h2 {
            font-size: 25px;
            padding-left: 20px;
            @include breakpoint($small-down) {
              margin: 0;
              color: $color-black;
            }
          }
          @include breakpoint($small-down) {
            width: 100%;
            padding-top: 6px;
          }
        }
        a.close {
          display: none;
          position: absolute;
          right: 16px;
          top: 0;
          text-decoration: none;
          z-index: 3;
          margin: 0;
          background: none;
          width: 35px;
          height: 40px;
          text-indent: 100%;
          background: none;
          span {
            display: none;
          }
          @include breakpoint($small-down) {
            display: block;
            right: 0px;
            width: 30px;
          }
          span {
            display: block;
            &:before {
              content: '^';
              position: absolute;
              top: 15px;
              color: $color-black;
              right: 15px;
              font-size: 40px;
            }
          }
        }
        .locations {
          margin-top: 30px;
          height: 100%;
          overflow-y: scroll;
          background-color: $color-white;
          font-family: $font--avenir-medium;
          .messages {
            background: none;
            border: 0px none;
            padding: 0px;
            .no-locations-found {
              margin: 5px;
            }
          }
        }
        .location {
          position: relative;
          cursor: pointer;
          overflow: hidden;
          padding: 1em 0.5em 1em 20px;
          margin: 0;
          border-bottom: 1px solid $color-bg;
          -webkit-transition: padding 0.5s, background 0.5s;
          transition: padding 0.5s, background 0.5s;
          text-transform: uppercase;
          .detail_reveal {
            display: none;
          }
          &.selected {
            color: $color-white;
            .detail_reveal {
              border-width: 0;
              display: block;
            }
            & > .address {
              display: none;
            }
          }
          &:hover {
            background: #e8e6e6;
            color: $color-black;
            border: none;
          }
          &:last-child {
            margin-bottom: 50px;
          }
          p {
            margin: 0 0 0.5em;
            font-size: 13px;
          }
          .image {
            float: left;
            width: 100px;
            margin: 0 1em 0 0;
            display: none;
            img {
              width: 100%;
            }
          }
          .distance {
            position: absolute;
            top: 21px;
            right: 10px;
            .uom {
              text-transform: lowercase;
            }
            p {
              font-size: 12px;
            }
          }
          .title {
            letter-spacing: 0;
            font-weight: normal;
            width: 75%;
            span {
              color: $color-black;
              text-transform: uppercase;
              font-size: 1.2rem;
              border: 0;
              padding: 0.5em 0;
              margin-bottom: 10px;
              display: inline-block;
            }
            .distance {
              display: none;
            }
          }
          .button {
            margin-top: 5px;
            border-width: 0 !important;
            background: none;
            width: 100%;
            padding: 0;
            box-shadow: none;
            a {
              color: $color-black;
              text-align: left;
              width: auto;
              font-size: 12px;
              padding: 0;
              box-shadow: none;
              display: inline-block;
              font-family: $font--avenir-roman;
              font-weight: normal;
              text-decoration: underline;
            }
          }
          .select-access-point {
            margin-right: 20px;
          }
          .location_more_info {
            display: none;
          }
        }
        .map {
          .location {
            min-width: 200px;
          }
        }
        p {
          margin: 0 0 0.5em;
        }
        .button {
          overflow: hidden;
          box-shadow: none !important;
          a {
            color: $color-white;
            cursor: pointer;
            font-family: $font--avenir-roman;
            font-size: 11px;
            margin-bottom: 3px;
            padding: 2px 5px;
            text-align: left;
            text-transform: uppercase;
            white-space: nowrap;
            letter-spacing: 0px;
            float: left;
            border: 0px none;
          }
        }
      }
      .active {
        a.close {
          right: 11px;
          width: 30px;
          @include breakpoint($small-down) {
            right: 0px;
          }
        }
      }
      .address-map-location-panel.active {
        a.close {
          span:before {
            content: attr(data-active);
          }
        }
        @include breakpoint($small-down) {
          top: 0px;
          height: auto;
          padding-right: 0px;
        }
      }
      .map {
        height: 100%;
        width: 100%;
      }
      .map-info-window {
        border: 1px solid $color-text-gray;
        padding: 10px;
        background: $color-white;
        .show-access-point {
          display: none;
        }
        .title {
          .distance {
            display: none;
          }
        }
        .location {
          width: 238px;
          padding: 0 5px;
          .title {
            padding-bottom: 5px;
            font-weight: bold;
          }
          .button {
            clear: both;
            border: none;
            background: none;
            box-shadow: none;
            width: 100%;
            padding: 0px;
            a {
              background: $color-black;
              box-shadow: none;
              font-size: 16px;
              font-weight: normal;
            }
            a.select-access-point {
              margin-bottom: 10px;
            }
          }
          .image {
            img {
              max-width: 100% !important;
            }
            @include breakpoint($small-down) {
              display: none;
            }
          }
          .location_more_info {
            cursor: pointer;
            text-decoration: underline;
            display: none;
            @include breakpoint($small-down) {
              display: block;
              margin-bottom: 10px;
            }
          }
          .opening_hours {
            @include breakpoint($small-down) {
              display: none;
            }
          }
          p {
            margin-bottom: 8px;
          }
        }
        .button {
          overflow: hidden;
          a {
            background: $color-black;
            color: $color-white;
            cursor: pointer;
            font-family: $font--avenir-roman;
            font-size: 12px;
            font-weight: bold;
            padding: 5px 0px !important;
            text-align: center;
            text-transform: uppercase;
            float: left;
            border: 0px none;
          }
        }
      }
      .directions {
        background: $color-white;
        color: $color-white;
        overflow-y: scroll;
        max-height: 100%;
      }
      .address-map-directions-panel {
        top: 0px;
        bottom: 0px;
        display: block;
        position: absolute;
        left: 0px;
        width: 26em;
        z-index: 2;
        -webkit-transition: left 0.5s;
        transition: left 0.5s;
        @include breakpoint($small-down) {
          top: 0px;
          left: 0px;
          width: 100%;
          overflow-y: auto;
          padding-right: 0px;
        }
      }
      .address-map-location-panel {
        top: 0;
        bottom: 0;
        display: block;
        position: absolute;
        width: 22em;
        z-index: 2;
        @include breakpoint($small-down) {
          top: auto;
          left: 0px;
          bottom: 0px;
          width: 100%;
          height: 30px;
          margin: 0;
          padding-bottom: 0;
          overflow: hidden;
        }
      }
    }
    .search-box-cancel {
      span:before {
        padding-top: 5px;
      }
    }
    .search-box-geolocate {
      span:before {
        font-size: 25px;
        line-height: 25px;
        padding: 2px 0px 2.5px 3.5px;
      }
    }
  }
}

html.mac {
  .click_and_collect_map {
    .overlay-content {
      .map-container {
        .address-map-directions-panel {
          header {
            height: 40px !important;
          }
        }
        .address-map-location-panel {
          header {
            height: 40px !important;
          }
        }
      }
    }
  }
}

.click_and_collect_map.local-collection {
  .overlay-content {
    .map-container {
      .address-map-search-panel {
        .search-box-toggle {
          background: $color-white;
          width: 80px;
          font-size: 16px;
        }
      }
    }
  }
}

.click_and_collect_map.store-collection {
  .overlay-content {
    .map-container {
      .address-map-search-panel {
        .search-box-toggle {
          background: $color-black url(/media/export/cms/cc/map_stores_toggle.png) no-repeat center center;
          width: 80px;
        }
      }
    }
  }
}

.local-collection-map {
  height: 100%;
  iframe {
    border: 0;
    width: 100%;
    height: 97%;
  }
}

.map-container .address-map-location-panel .location:hover,
.map-container .address-map-location-panel .location.selected {
  padding: 2em 0.5em;
  background: #c7c7c7;
  border: 1px solid $color-black;
}

.address-map-location-panel .button a {
  background: #2b2b2b;
  color: $color-white;
  cursor: pointer;
  font-family: $font--avenir-roman;
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 3px;
  text-align: left;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0px;
  margin-right: 8px;
  float: left;
  border: 0px none;
  padding: 2px 5px;
}

.click_and_collect_map .overlay-content .map-container .active a.close {
  background: $color-white;
}

.click_and_collect_map .overlay-content .search-box-geolocate span:before {
  line-height: 35px;
}

@include breakpoint($medium-down) {
  .click_and_collect_map .overlay-content .map-container .address-map-search-panel .status-bar-wrapper {
    position: fixed;
    bottom: 50px;
    width: 100%;
    margin: 0;
    left: 0;
    text-align: center;
  }
  .search-box-cancel {
    line-height: 38px;
    top: 0.5em;
    right: 0.5em;
  }
  .search-box-geolocate {
    bottom: 6%;
    right: 0.8em;
  }
}

.local-collection {
  .overlay-content {
    .map-container {
      .search-box-toggle {
        .toggle-img {
          background: url('/media/export/cms/cc/sbx_off.png') no-repeat 0px 15px;
          height: 2em;
          width: 3.1em;
        }
      }
    }
  }
}

.store-collection {
  .overlay-content {
    .map-container {
      .search-box-toggle {
        .toggle-img {
          background: url('/media/export/cms/cc/ups_icon.png') no-repeat 1px 2px;
          height: 2.5em;
          width: 3.1em;
        }
      }
    }
  }
}

div#map-foreground-node {
  &.overlay-container.local-collection.click_and_collect_map {
    @include breakpoint($small-down) {
      height: 100% !important;
    }
  }
}
