/* Tool Tip */
.social-login,
.profile-page {
  .tooltip {
    display: block;
    text-align: $ldirection;
    margin-top: 10px;
  }
  .social-info__content {
    p {
      margin-top: 10px;
    }
  }
}
/* Order confirmation */
.checkout--confirmation-page {
  .social-login {
    padding-#{$ldirection}: 0;
    &__container {
      .social-login__title {
        font-size: 19px;
        @include breakpoint($medium-up) {
          font-size: 28px;
        }
      }
    }
    &__email-opt-in {
      line-height: 24px;
    }
  }
}

.signin-block {
  .social-login.gnav {
    .tooltip {
      .tooltiptext-under {
        overflow-y: auto;
        top: 110%;
      }
      .tooltiptext-over,
      .tooltiptext-under {
        height: 200px;
        width: auto;
        &::before {
          content: none;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
}
/* Account Signin */
.social-login {
  &__container {
    padding: 0;
    @include breakpoint($medium-up) {
      padding: 20px 0;
    }
  }
  &__divider {
    margin-top: 0;
  }
}
/* GNAV */
.signin_container .gnav .fb-disclaimer-container {
  text-align: #{$ldirection};
}

.gnav-util--account .gnav-util__content {
  overflow-y: scroll;
  height: 525px;
}

.tooltip .tooltiptext-over {
  overflow-y: auto;
  bottom: 110%;
}
/* Account signin page */
.outer-wrap .social-login__container .disclaimer {
  text-align: #{$ldirection};
}
/* Checkout */
#main.single {
  .sign-in-panel input {
    width: auto;
    vertical-align: middle;
  }
  .panel label {
    display: inline;
  }
}

.fb-disclaimer-error {
  color: $color-red;
  padding-bottom: 10px;
}

.fb-overlay-container {
  width: 265px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.social-info__connect {
  margin-bottom: 0;
}

.fb-overlay-social-info {
  display: inline-flex;
  width: 164px;
  height: 22px;
  position: relative;
  .fb-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
}

.disclaimer {
  position: relative;
  display: flex;
  margin-top: 20px;
  .fb-disclaimer {
    text-align: #{$ldirection};
  }
}

.disclaimer .js-facebook_disclaimer {
  left: 0px;
  opacity: 0;
}
/* Checkbox Alignment issue */
.social-login,
.disclaimer,
.social-login__email-opt-in {
  input[type='checkbox'] ~ label {
    margin-left: 20px;
    &::before {
      margin-left: -20px;
    }
  }
}
