body {
  &.section-privacy-policy {
    .site-content {
      .cs-page__content.sidebar-page__content.js-grid-item {
        @include breakpoint($medium-up) {
          height: auto !important;
        }
      }
    }
  }
  .applepay-enabled {
    .paypal-smart-button {
      margin-top: 75px;
    }
  }
}

.site-header {
  &__section--utilities {
    .country-chooser {
      .country-select {
        width: auto;
        float: left;
        margin: 0px;
        line-height: 60px;
        .country-select-img {
          margin: 0px;
          height: auto;
          vertical-align: baseline;
        }
      }
      .language-select {
        line-height: 60px;
        padding-right: 0.5em;
        &.pc-hidden {
          display: inline-block;
        }
        &__menu {
          font-family: 'avenir-book', sans-serif;
          font-size: 10px;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          margin-right: 10px;
          .OneLinkKeepLinks {
            display: inline-block;
            a {
              color: white;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.country-select {
  &__selectbox-selectBox-dropdown-menu {
    min-width: 150px;
  }
}

#cboxLoadedContent {
  .product-quickshop {
    &__rating {
      &.pc-only {
        display: none !important;
      }
    }
  }
}

.mpp-container {
  .product-grid-wrapper {
    .product-grid {
      &__item {
        .product-brief {
          &__container {
            .sku-unit-price {
              display: none;
            }
          }
        }
      }
    }
  }
  .product-brief__price {
    @include breakpoint($large-up) {
      margin: 1em 0 7px 0;
    }
  }
}

.product-grid-wrapper {
  .product-brief,
  .product-grid__item {
    .product-brief__price {
      .product__price {
        &--non-sale {
          text-decoration: line-through;
        }
        &--sale {
          color: $color-red;
        }
      }
    }
  }
}

.checkout {
  .checkout-panel--sign-in-main {
    h3.checkout-order-date__header {
      float: right;
      font-size: 16px;
      padding-top: 10px;
    }
    .checkout-order-date_content {
      float: right;
    }
  }
  &.adyen_enabled {
    .gift-options-display-content {
      width: 100%;
    }
    .checkout-gift-note {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 49%;
        float: #{$ldirection};
      }
    }
    .checkout-gift-message {
      width: 100%;
      @include breakpoint($medium-up) {
        width: 49%;
        float: #{$rdirection};
      }
    }
    .view-address {
      padding-bottom: 23px;
      .change-address {
        float: #{$rdirection};
        text-transform: uppercase;
        font-weight: bold;
        margin-#{$rdirection}: 20px;
        font-size: 12px;
        text-decoration: none;
        @include breakpoint($medium-up) {
          font-size: 16px;
          text-decoration: underline;
        }
      }
      .checkout {
        &__panel {
          &--heading {
            font-size: 19px;
            @include breakpoint($medium-up) {
              font-size: 28px;
            }
            font-weight: bold;
            margin-bottom: 17px;
          }
        }
      }
    }
    .place-order {
      &__title {
        border-top: 1px solid #{$color-light-gray};
        padding-top: 20px;
      }
    }
    .billing-address-display-content {
      &__heading {
        font-size: 16px;
        @include breakpoint($medium-up) {
          font-size: 19px;
        }
      }
    }
    .gift-card-entry-container {
      max-width: 384px;
      @include breakpoint($medium-up) {
        max-width: 500px;
      }
      .btn-mini {
        max-width: 384px;
        margin-top: 15px;
        width: 100%;
        @include breakpoint($medium-up) {
          max-width: 390px;
          margin-#{$ldirection}: 10px;
          margin-top: 0;
        }
        height: 44px;
      }
      .gift-card-number {
        width: 59%;
        float: #{$ldirection};
        input {
          max-width: 240px;
        }
      }
      .gift-card-pin {
        width: 39%;
        float: #{$rdirection};
        input {
          max-width: 150px;
        }
      }
    }
    .pay-with-label {
      font-weight: bold;
      font-size: 19px;
    }
    .form-container {
      max-width: 610px;
      .payment-option {
        &:first-child {
          margin-top: 20px;
          @include breakpoint($medium-up) {
            margin-top: 0;
          }
          padding-top: 21px;
          border-top: 1px solid #{$color-black};
        }
      }
    }
    .payment-option {
      @include swap_direction(padding, 21px 0);
      width: 100%;
      border-bottom: 1px solid #{$color-black};
      .field {
        &:checked {
          & + label {
            .payment_options-terms {
              display: block;
            }
          }
        }
      }
    }
    .all_card_img {
      height: 24px;
      margin-#{$ldirection}: 10px;
    }
    .payment_options-terms {
      display: none;
      width: 100%;
      padding-#{$ldirection}: 16px;
      .payment-terms {
        font-size: 15px;
        text-transform: none;
        margin-top: 10px;
        .overlay-wrapper {
          display: inline-block;
          .overlay-link-cms {
            font-weight: normal;
          }
        }
      }
    }
    .chckt-pm {
      border: none;
      &__header {
        padding-#{$ldirection}: 19px;
      }
      &__image {
        margin-#{$ldirection}: 0;
      }
      &__details {
        padding-#{$ldirection}: 5px;
        padding-bottom: 0;
      }
      .chckt-checkbox {
        position: static;
      }
      .chckt-input-field {
        @include swap_direction(padding, 6px 10px 5px 10px);
        border-radius: 0;
        height: 44px;
        border-color: #{$color-black};
        width: 100%;
        &--error {
          color: #{$color-red};
          border: 1px solid #{$color-red};
        }
        &--recurring {
          border: 1px solid #{$color-black};
        }
        &--cvc {
          width: 100%;
          max-width: 174px;
        }
      }
      .chckt-form-label {
        &--exp-date {
          width: 52%;
          @include breakpoint($medium-up) {
            width: 46%;
          }
        }
        &--cvc {
          width: 36%;
          @include breakpoint($medium-up) {
            width: 46%;
          }
          padding-#{$ldirection}: 22px;
        }
        &--full-width {
          width: 96%;
        }
        &__error-text {
          color: #{$color-red};
          text-transform: none;
          font-weight: normal;
        }
      }
    }
    .chckt-pm-list {
      .chckt-button-container {
        padding-#{$ldirection}: 16px;
        margin-bottom: 10px;
        .chckt-button {
          &--submit {
            @include swap_direction(padding, 10px);
            max-width: 370px;
            background-color: #{$color-black};
          }
          &--disabled {
            background-color: #{$color-gray};
          }
        }
        .chckt-more-pm-button {
          @include swap_direction(padding, 0);
          border: none;
          color: #{$color-black};
          &__icon {
            display: none;
          }
          &__text {
            text-decoration: underline;
            margin-#{$ldirection}: 0;
          }
        }
      }
    }
    .paypal-logo {
      vertical-align: middle;
      margin-#{$ldirection}: 10px;
    }
    .clearpay-logo {
      @include swap_direction(margin, 0 10px);
    }
    .clearpay-button {
      max-width: 400px;
      button {
        &:empty {
          display: none;
        }
      }
      .afterpay-button {
        width: 300px;
        @include breakpoint($medium-up) {
          width: 400px;
        }
        padding: 10px;
        .clearpay-adyen-logo {
          vertical-align: middle;
          padding-bottom: 3px;
        }
      }
    }
    .local-submits {
      .pc_hidden {
        display: none;
      }
    }
    .after_pay-installment {
      display: block;
      padding-#{$ldirection}: 10px;
      @include breakpoint($medium-up) {
        display: contents;
      }
    }
    .existing-address {
      .select-menu {
        select {
          width: 100%;
        }
      }
    }
    .shipping-gift-options-wrap {
      .shipping-address-display-content {
        &__addresses {
          @include breakpoint($medium-up) {
            width: 600px;
          }
        }
      }
    }
    .paypal-terms {
      .local-submits {
        .pc_hidden {
          display: block;
          @include breakpoint($medium-up) {
            display: none;
          }
        }
      }
    }
  }
}

@include breakpoint($landscape-up) {
  .checkout {
    &__sidebar {
      .checkout-panel--viewcart {
        .cart-items__item {
          &--qty {
            margin-top: 0px;
            width: 8%;
          }
          &--total {
            width: 30%;
            font-size: 16px;
          }
        }
      }
    }
  }
}

footer {
  .country-chooser {
    .language-select {
      &__menu {
        font-family: 'avenir-book', sans-serif;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        .OneLinkKeepLinks {
          display: inline-block;
          a {
            color: white;
            line-height: 24px;
          }
        }
      }
    }
  }
}

ul.error_messages li {
  color: #d74027;
}

#bu_footer_sticky {
  display: block;
}

.brand-story-formatter {
  &--salons {
    .brand-story-nav {
      margin: -30px 0 0 0;
      @include breakpoint($medium-up) {
        margin-bottom: 0px;
        margin-top: -71px;
      }
      &__more {
        display: none;
      }
    }
  }
}
/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  width: 100%;
  &.photo-tip {
    margin-bottom: 10px;
    .tooltiptext-over {
      font-size: 13px;
    }
  }
  a {
    text-decoration: none;
  }
  .tooltiptext-under,
  .tooltiptext-over {
    visibility: hidden;
    width: 100%;
    max-width: 400px;
    background-color: $color-white;
    color: #555;
    border: 1px solid $color-gray;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    left: 0%;
    opacity: 0;
    transition: opacity 0.3s;
  }
  .tooltiptext-under {
    top: 125%;
  }
  .tooltiptext-over {
    bottom: 125%;
  }
  .tooltiptext-under::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent $color-dark-gray transparent;
  }
  .tooltiptext-over::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 17%;
    border-width: 5px;
    border-style: solid;
    border-color: $color-dark-gray transparent transparent transparent;
  }
  &:hover .tooltiptext-under,
  &:hover .tooltiptext-over {
    visibility: visible;
    opacity: 1;
  }
  &--top-spacing {
    padding-top: 10px;
  }
  &--bottom-spacing {
    padding-bottom: 10px;
  }
  &--photo-spacing {
    padding-top: 50px;
  }
  &--privacy-link-spacing {
    margin-top: 10px;
  }
}

.checkout {
  .checkout-panel--new-account,
  .checkout-panel--confirmation {
    overflow: visible;
  }
}

.content-pad {
  .responsive-tout {
    @include breakpoint($small-down) {
      margin-bottom: -18px !important;
    }
  }
}

.product-carousel-tout__item--1 {
  .carousel-home {
    @include breakpoint($small-down) {
      display: none;
    }
  }
}

//One Trust Implementation
.optanon-alert-box-wrapper {
  .optanon-alert-box-bg {
    .optanon-alert-box-button-middle {
      .accept-cookies-button,
      .cookie-settings-button {
        &:active,
        &:hover {
          background: none;
        }
      }
    }
  }
}

.checkout-panel {
  &--offer-code {
    .fe_validatable {
      .form-item {
        input {
          &:required:invalid:not(:checked).checkedEmpty {
            border: 1px solid $color-error;
          }
        }
      }
    }
  }
}

.optanon-status {
  &-editable,
  &-on {
    input[type='checkbox'] {
      & ~ label {
        &:before {
          display: none;
        }
      }
    }
  }
}
/* Password GDPR validation */
.password-field {
  position: relative;
  &__info {
    margin-top: 5px;
    @include breakpoint($medium-up) {
      border: 1px solid #{$color-dark-gray};
      width: auto;
      position: absolute;
      top: -128%;
      left: 0;
    }
    &::before {
      @include breakpoint($medium-up) {
        content: '';
        position: absolute;
        top: 100%;
        right: 96%;
        border-width: 5px;
        border-style: solid;
        border-color: #{$color-black} transparent transparent transparent;
      }
    }
    .password-reset-page & {
      @include breakpoint($medium-up) {
        top: -37%;
        left: 191px;
        &::before {
          top: 42%;
          right: 100%;
          border-color: transparent #{$color-black} transparent transparent;
        }
      }
    }
    .checkout-panel--registration &,
    .sign-in-confirmation-content & {
      @include breakpoint($medium-up) {
        top: -174%;
      }
    }
    .password-field__rules {
      padding: 10px;
      background-color: #{$color-white};
      column-count: 2;
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3px 0;
        color: #{$color-black};
        transition: 0.2s;
        white-space: nowrap;
        line-height: 1.5;
        font-size: 11px;
        letter-spacing: initial;
        &.pass {
          color: #{$color-valid};
          &::before {
            color: #{$color-valid};
            content: '\2713';
            text-shadow: 0 0 8px #{$color-black};
          }
        }
        &::before {
          content: '\2715';
          display: inline-block;
          color: #{$color-red};
          font-size: 1em;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
      }
    }
  }
}

.product-full__add-to-bag {
  .button {
    margin-top: 7px;
  }
}

.paypal-processing {
  @include swap_direction(padding, 10px);
  @include swap_direction(margin, 0 auto);
  #{$ldirection}: 0;
  position: fixed;
  #{$rdirection}: 0;
  text-align: center;
  top: 10vw;
  width: 100%;
  z-index: 999;
  opacity: 1;
  .process-content {
    @include swap_direction(padding, 30px 10px);
    @include swap_direction(margin, 0 auto);
    background: #{$color-white};
    max-width: 335px;
    z-index: 999;
    position: relative;
    opacity: 1;
  }
  .paypal-logo {
    padding-top: 20px;
    width: 90px;
  }
  .bumble-logo-processing {
    padding-bottom: 25px;
  }
  .paypal-overlay {
    background: #{$color-black};
    opacity: 0.7;
    #{$ldirection}: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }
}

.myaccount-adyen-add-payment {
  .account-section {
    &__nav {
      @include breakpoint($medium-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
  padding-top: 30px;
  .payment_address {
    border: none;
    width: 100%;
    @include breakpoint($medium-up) {
      width: 50%;
    }
    .adyen-fields {
      .adyen-checkout {
        &__input {
          border-radius: 0;
          height: 45px;
        }
      }
    }
    .payment-form {
      &__adyen {
        &--option {
          width: 100%;
          margin-top: 5px;
        }
      }
    }
  }
  .non-adyen-fields {
    .payment-address {
      @include breakpoint($medium-up) {
        width: 50%;
      }
      .selectbox {
        @include swap_direction(margin, 10px);
        width: 90%;
      }
    }
  }
}

.adyen-viewcart-buttons-panel {
  .checkout-buttons-content {
    &__container {
      .paypal-smart-button {
        margin-bottom: 10px;
        @include breakpoint($medium-up) {
          float: #{$ldirection};
          margin-bottom: 0;
        }
      }
    }
  }
}

//Clearpay Popup styles override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    height: 95%;
    width: 95%;
    @include breakpoint($medium-up) {
      height: 75%;
      width: 70%;
    }
  }
  .disclaimer {
    display: block;
  }
}
