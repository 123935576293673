// Customer & Services Styles

#bt_settings div {
  &.bt-content {
    border: 1px solid black;
    padding: 15px;
    background-color: white;
    z-index: 1001;
    line-height: 16px;
    border-color: #000;
    border-width: 2px;
    border-radius: 6px;
    ul {
      margin: 0;
      li {
        list-style-type: none;
      }
      &.slide-toggle {
        position: relative;
        overflow: hidden;
        width: 525px;
        background: transparent url(/media/images/cookies/all-off.png) 50% 100% no-repeat;
        background-size: contain;
        li {
          cursor: pointer;
          float: left;
          width: 175px;
          height: 149px;
          &.middle {
          }
          &.selected {
          }
          span {
            display: block;
            font-weight: bold;
            text-align: center;
            margin: 5px;
            display: none;
          }
        }
      }
    }
    div {
      &.bt-intro {
        h2 {
          text-transform: none;
          color: #000;
        }
        div.bt-intro-copy p {
          margin-top: 1em;
        }
      }
      &.slide {
        position: relative;
        overflow: hidden;
        width: 525px;
        margin: 20px auto;
      }
      &.mask {
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        width: 0;
        border-right: 1px solid #eee;
        ul.slide-toggle {
          background-image: url(/media/images/cookies/all-on.png);
        }
        div.drag {
          position: absolute;
          bottom: 12px;
          right: 70px;
          width: 26px;
          height: 11px;
          background: transparent url(/media/images/cookies/icon_arrow.png) 50% 100% no-repeat;
        }
      }
    }
    .bt-privacy-info {
      display: none;
      overflow: hidden;
      &.selected {
        display: block;
      }
      h3 {
        padding: 1em 1em 1em 40px;
        background-position: 10px 50%;
        background-repeat: no-repeat;
        background-color: #f1f1f1;
      }
      div {
        width: 48%;
        margin-right: 2%;
        float: left;
        &.bt-privacy-will {
          h3 {
            background-image: url(/media/images/cookies/icon_safe_lock.png);
          }
          ul li {
            background-image: url(/media/images/cookies/icon_safe_thick.png);
          }
        }
        &.bt-privacy-willnot {
          h3 {
            background-image: url(/media/images/cookies/icon_unsafe_lock.png);
          }
          ul li {
            background-image: url(/media/images/cookies/icon_unsafe_thick.png);
          }
        }
        ul {
          list-style-type: none;
          padding-left: 0;
          margin: 1em;
          li {
            margin: 0.8em 0;
            padding-left: 30px;
            background-position: 0 50%;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &.bt-content-mobile {
    div {
      &.mask {
        ul.slide-toggle {
          background-image: url(/media/images/cookies/m_all-on.png);
        }
        div.drag {
          right: 36px;
          right: 30px;
          bottom: 11px;
        }
      }
      &.slide {
        margin: 20px auto;
        width: 288px;
        width: 246px;
      }
    }
    ul.slide-toggle {
      background-image: url(/media/images/cookies/m_all-off.png);
      width: 288px;
      width: 246px;
      li {
        width: 96px;
        height: 159px;
        width: 82px;
        height: 136px;
        span {
        }
      }
    }
    .bt-privacy-info div {
      width: 100%;
      float: none;
    }
  }
  &.bt-content-desktop {
    padding: 2em;
    height: 100%;
  }
}

#bt_notification {
  width: 100%;
  .bt-content {
    color: $color-white;
    border: 1px solid $color-white;
    border-bottom: 1px solid $color-black;
    background-color: $color-black;
    padding: 25px 30px 25px 22px;
    word-wrap: break-word;
    position: fixed;
    right: 0;
    z-index: 99999;
    .bt-close-link {
      position: absolute;
      right: 5px;
      top: 6px;
      width: 15px;
      height: 15px;
      display: inline-block;
      background: url(/media/export/images/cookies/close_x.png) 50% 50% no-repeat;
      text-indent: -9999em;
    }
    .bt-pp-section {
      margin-bottom: 10px;
    }
    .btn {
      border: 1px solid #fff;
      display: block;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
      font-family: avenir-heavy, sans-serif;
      font-size: 14px;
      text-decoration: none;
      text-align: center;
    }
  }
  a {
    color: $color-white;
    text-transform: uppercase;
  }
  @include breakpoint($landscape-up) {
    width: 390px;
  }
}

.tealium {
  display: none !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 736px) {
  #bt_settings div.bt-content ul.slide-toggle {
    width: 246px;
    background-size: 246px 147px;
  }
  #bt_settings div.bt-content ul.slide-toggle li {
    width: 82px;
    height: 148px;
  }
  #bt_settings div.bt-content .bt-privacy-info div {
    width: 100%;
  }
  #bt_settings div.bt-content div.mask div.drag {
    right: 30px;
  }
}
