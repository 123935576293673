.sticky__footer__menu .sticky__menu {
  display: inline-block;
}

.samples-page {
  ul.product-list {
    .product {
      padding-left: 1em;
    }
  }
}

.viewcart {
  .checkout-panel {
    .single-message:first-child {
      padding-top: 0px;
    }
    &--promo {
      .messages {
        margin-top: 0px;
      }
    }
    .messages {
      margin-top: 0px;
    }
  }
}
