// Basic Colors - descriptive names
$light-gray: #f6f6f6;
$dark-gray: #f6f6f6;
$xxsmall-down: max-width 360px;

.perlgem {
  .page-wrapper {
    .site-header {
      left: 0px;
      top: 0px;
    }
  }
  &.checkout {
    padding: 0;
    max-width: 100%;
    input[type='checkbox']:required:not(:checked) {
      &.checkedEmpty ~ label {
        color: #e20400;
      }
    }
    .section-sms-promotions {
      .sms-mobile {
        &-phone {
          display: inline-block;
          width: 70%;
        }
        &-prefix {
          float: left;
          border: 1px solid black;
          height: 40px;
          line-height: 40px;
          padding: 0 0 0 10px;
          margin-right: 1%;
          display: inline-block;
          width: 20%;
        }
      }
    }
    input[type='text']:required {
      &.checkedEmpty ~ label {
        color: #e20400;
      }
    }
  }
}

.mobile_hidden {
  display: none !important;
}

// !important is used below to override the inline styles from 3rd party content
.afterpay-paragraph {
  width: 90%;
  .afterpay-text1 {
    margin-#{$rdirection}: 4px;
  }
  .afterpay-link {
    background: url(/media/images/checkout/utillity_information.svg) no-repeat;
    width: 20px;
    height: 20px;
    text-decoration: none !important;
    color: transparent !important;
    vertical-align: middle;
    margin-#{$ldirection}: 6px;
  }
}

.viewcart {
  &.checkout {
    .checkout-panel {
      &--offer-code {
        margin: 0;
        padding: 1em;
        background: #fbf8f6;
      }
      &--bottom-section,
      &--viewcart-buttons {
        margin: 0;
        background: $color-white;
        .paypal-checkout {
          display: block;
          float: left;
          margin: 0 20px 0 0;
          height: 35px;
          width: 100%;
          text-align: center;
          img {
            width: auto;
            margin: 3px auto;
          }
        }
        .paypal-checkout.disabled {
          img {
            opacity: 0.2;
            filter: alpha(opacity=20);
          }
        }
        .checkout-buttons-content__container {
          width: 100%;
          margin-top: 12px;
        }
      }
      &--viewcart {
        .cart-items__item {
          &--total {
            bottom: 0;
            margin: 0;
            float: right;
            width: auto;
            padding-left: 0px;
            padding-right: 10px;
            @include breakpoint($xxsmall-down) {
              width: auto;
              font-size: 12px;
            }
            &.free-sample-item {
              display: block;
            }
          }
          &--thumb {
            height: 150px;
            @include breakpoint($xxsmall-down) {
              width: 80px;
            }
          }
          &--desc {
            width: 60%;
            @include breakpoint($xxsmall-down) {
              padding-left: 5px;
              width: 60%;
            }
            .cart--item__remove-form {
              margin-bottom: 10%;
            }
          }
          &--qty {
            margin: 0;
            margin-top: -10px;
            text-align: center;
            margin-left: 10px;
            width: 20%;
            @include breakpoint($xxsmall-down) {
              margin-top: -10px;
              padding: 0;
            }
            .selectbox {
              width: auto;
              @include breakpoint($xxsmall-down) {
                width: auto;
              }
            }
          }
          &--price {
            clear: both;
            float: left;
            padding: 0;
            margin: 0;
            width: 90px;
            text-align: center;
            @include breakpoint($xxsmall-down) {
              width: 80px;
            }
            .sale-price {
              margin-right: 0;
              display: inline-block;
            }
          }
        }
      }
      &--offer-code {
        .checkout-panel {
          &__header {
            padding-top: 0.5em;
          }
          &__heading {
            margin: 0;
          }
          &__content {
            .offer-code-content__view--all--offers {
              margin: 0;
              padding: 0;
            }
          }
        }
      }
    }
    .donations {
      overflow: visible;
      &.panel {
        border-top: none;
        border-right: none;
        border-left: none;
        #charity-header {
          margin-bottom: 12px;
          border-bottom: 1px solid #ebebeb;
          width: 100%;
          font-weight: normal;
          font-size: 16px;
          padding: 0.8em 0;
        }
        .donation_info_panel {
          float: left;
          margin-bottom: 1em;
          width: 100%;
          float: left;
          .donation_logo_mobile {
            width: 100%;
          }
          .donation_logo,
          .donation_message {
            @include mobile-hidden;
          }
          .donation_logo_mobile,
          .donation_message_mobile {
            @include pc-hidden;
          }
          .donation_logo,
          .donation_logo_mobile {
            height: 100%;
            float: left;
            margin-right: 2em;
          }
          .donation_message {
            width: 100%;
            float: right;
          }
        }
        .content {
          padding-left: 0;
          .donation_amounts_panel {
            clear: both;
            margin-top: 15px;
            span#select-amount {
              @include mobile-hidden;
              float: left;
              width: 30%;
              font-weight: bold;
              font-size: 16px;
            }
            span#select-amount-mobile {
              @include pc-hidden;
              float: left;
              width: 100%;
              margin: 1em 0;
              font-size: 16px;
              font-weight: bold;
            }
            .donation-group {
              display: inline;
              font-size: 16px;
              width: 70%;
              label {
                display: inline;
                margin-right: 10px;
              }
            }
          }
        }
        .donationMessages {
          &.messages {
            margin: 0;
            padding: 0;
            .single-message {
              margin: 0.5em 0;
            }
          }
        }
      }
    }
    #shipmethod-panel {
      margin: 1em 0;
      .checkout-panel {
        &__header {
          margin: 0;
          h2 {
            margin-bottom: 0;
            border-bottom: 1px solid #ebebeb;
            width: 100%;
            font-weight: normal;
            font-size: 16px;
            padding: 0.8em 0;
          }
        }
        &__heading {
          margin: 0;
          padding: 0;
        }
      }
      .content {
        width: 100%;
        .ship-method-group-label {
          .shipment-option {
            padding: 0;
            input {
              vertical-align: middle;
            }
          }
        }
        .ship-method-home-group {
          margin: 0;
          select {
            width: 100%;
          }
        }
        .shipment-option {
          clear: both;
          padding: 0;
          label {
            margin: 1em 0;
          }
        }
      }
    }
    .checkout-panel--bottom-section {
      background: none;
      .bottom-section-content {
        background: none;
        #order-summary-panel {
          background: none;
          .checkout-panel {
            &__content {
              .order-summary-content__label {
                text-align: left;
                width: 60%;
                .shipping-label {
                  &mobile_hidden {
                    background-color: green;
                  }
                  &pc_hidden {
                    background-color: blue;
                  }
                }
              }
              .order-summary-content__value {
                text-align: right;
              }
            }
            &--viewcart-buttons {
              .afterpay {
                .afterpay-learnmore {
                  .info-icon {
                    @include swap_direction(margin, 0 1px 0 2px);
                    width: 21px;
                    height: 20px;
                    vertical-align: middle;
                    display: inline-block;
                    cursor: pointer;
                  }
                }
              }
              .total-container__content {
                .total {
                  &.label {
                    text-align: left;
                    width: 50%;
                    display: inline-block;
                  }
                  &.value {
                    float: right;
                    text-align: left;
                    display: inline-block;
                  }
                }
              }
              .bottom-viewcart-buttons {
                background: none;
                .checkout-buttons-content__container {
                  .checkout-buttons-content__item {
                    &.continue-checkout {
                      width: 100% !important;
                    }
                  }
                  .paypal-checkout {
                    @extend .button;
                    margin-bottom: 1em;
                    padding: 0;
                    background: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  #order-summary-panel {
    background: $color-white;
    padding-top: 10px;
    .checkout-panel--order-summary {
      background: $color-white;
    }
    .total {
      border: 0;
      font-weight: bold;
      &.afterpay {
        @include swap_direction(margin, 2% 0 0 10%);
        width: 90%;
        text-align: #{$rdirection};
      }
    }
  }
  .checkout__sidebar {
    margin: 0;
    .tab-switcher {
      display: none;
    }
    .checkout-panel--need-help {
      .need-help-content {
        .need-help-content__item {
          margin-bottom: 5%;
        }
      }
    }
    #shipmethod-panel {
      margin-top: 10px;
    }
  }
  .panel--viewcart {
    .cart-items__item--thumb {
      height: 150px;
    }
  }
}

body#index {
  .page-wrapper {
    .site-content {
      .pg_wrapper {
        &.checkout {
          &.checkout-single-page {
            .checkout__content {
              &.left {
                #sign-in-panel {
                  padding: 1em;
                  overflow: visible;
                  .new-or-returning-content {
                    fieldset {
                      margin: 5% 0;
                    }
                  }
                  &.finished {
                    display: block;
                    .checkout-panel__content {
                      line-height: 20px;
                    }
                  }
                  &.checkout-panel--sign-in-main {
                    .checkout-panel__content {
                      margin-bottom: 23px;
                    }
                  }
                }
                .messages {
                  margin: 0;
                  .single-message {
                    padding: 0;
                    margin: 0;
                  }
                }
                .checkout-panel--shipping {
                  padding-bottom: 10%;
                  .btn {
                    width: 100%;
                  }
                  &.finished {
                    display: block;
                  }
                  .checkout-panel__header {
                    a.button {
                      &.edit {
                        &.link {
                          border: none;
                          height: auto;
                          background: none;
                          font-weight: normal;
                          font-size: 12px;
                          margin-top: 0;
                        }
                      }
                    }
                  }
                  .checkout-panel__content {
                    .form-container {
                      .form-item,
                      select {
                        width: 100%;
                      }
                    }
                    #qas {
                      overflow: visible;
                      .manual-address {
                        margin: 15px 0;
                      }
                      .error {
                        overflow: hidden;
                        color: $color-red;
                      }
                    }
                    #manual-address-fields {
                      margin-top: 20px;
                      float: left;
                      width: 100%;
                    }
                    .address-to-use {
                      margin-bottom: 15px;
                      label {
                        float: left;
                        margin-right: 25px;
                        padding: 0 0 5% 0;
                        width: 100%;
                        display: block;
                        text-transform: uppercase;
                        @include breakpoint($small-down) {
                          text-transform: none;
                        }
                        &:before {
                          margin-top: -4px;
                        }
                      }
                    }
                    .select-menu {
                      margin-bottom: 5%;
                    }
                  }
                  .shipping-gift-options-wrap {
                    h3 {
                      font-size: 16px;
                    }
                    font-weight: normal;
                  }
                }
                #sign-in-paypal-panel {
                  margin: 0;
                  fieldset {
                    margin: 1em 0;
                  }
                }
                #review-panel {
                  &.checkout-panel.collapsed {
                    padding-bottom: 20px;
                  }
                  &.checkout-panel--review {
                    margin: 0;
                    .place-order__title {
                      margin: 0;
                    }
                    .messages {
                      .single-message {
                        margin: 0;
                        padding: 0;
                      }
                    }
                    .review-edit-content {
                      margin: 0;
                      padding: 0;
                      .content {
                        .field-are-required {
                          padding: 0;
                        }
                        h3 {
                          padding: 0;
                        }
                        .gift-cards {
                          .form-container {
                            form#giftcard_balance {
                              .form-item {
                                margin-top: 5%;
                                input {
                                  width: 100%;
                                }
                              }
                            }
                          }
                        }
                        .address {
                          margin-top: 5%;
                          .view-address {
                            .select-address {
                              .same-as-shipping {
                                margin: 2% 0;
                              }
                            }
                          }
                          .set-address {
                            h3 {
                              font-size: 19px;
                            }
                            input,
                            select {
                              border: 1px solid $color-black;
                              width: 100%;
                            }
                            .select-address {
                              .address-to-use {
                                .billing-option {
                                  margin: 5% 0;
                                  display: block;
                                }
                              }
                              .new-address {
                                &.add {
                                  .address-form {
                                    input,
                                    select {
                                      border: 1px solid $color-black;
                                    }
                                    #qas {
                                      .error {
                                        color: #d74027;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        .terms_condition {
                          padding: 10% 0;
                          width: 100%;
                          input {
                            width: 100%;
                          }
                          .submit {
                            padding: 10% 0 0 0;
                          }
                        }
                        .payment-method-header {
                          margin-top: 2em;
                          padding: 0;
                        }
                        form#checkout_billing_panel {
                          padding: 0 1em;
                          .payment-method__item {
                            div.payment-option {
                              .related-media {
                                span {
                                  display: block;
                                  margin-top: 3%;
                                  margin-bottom: 10%;
                                }
                              }
                            }
                            span.payment-option {
                              margin: 5% 0;
                              display: block;
                            }
                          }
                        }
                        form#checkout_saved_payment {
                          margin: 1em;
                          h3 {
                            font-size: 15px;
                          }
                          .card-cvn {
                            a {
                              display: block;
                              padding-top: 2%;
                            }
                          }
                        }
                        .form-container {
                          form#checkout_payment {
                            .payment-form__item {
                              .form-item {
                                margin-bottom: 5%;
                                select,
                                input {
                                  width: 100%;
                                }
                                .card-type {
                                  label {
                                    margin: 1em 0;
                                  }
                                }
                              }
                            }
                            .card-expiry-date {
                              width: 100%;
                              .card-expiry {
                                margin: 0;
                                width: 40%;
                                display: inline-block;
                              }
                            }
                            .card-cvn {
                              a {
                                margin-top: 3%;
                              }
                            }
                          }
                        }
                      }
                      .creditcard-option {
                        @include swap_direction(padding, 20px 0 10px);
                        width: 100%;
                        border-top: 1px solid #{$color-black};
                        margin-top: 10px;
                      }
                      label {
                        margin-#{$ldirection}: 15px;
                      }
                      .payinfo-text {
                        font-family: $font--avenir-heavy;
                        font-size: 16px;
                      }
                      .cc {
                        span {
                          width: 100%;
                          float: #{$ldirection};
                          padding-top: 10px;
                        }
                      }
                      span {
                        &.creditcard-media {
                          display: none;
                        }
                      }
                      .paypal-option {
                        @include swap_direction(padding, 20px 0);
                        width: 100%;
                        border-top: 1px solid #{$color-black};
                        border-bottom: 1px solid #{$color-black};
                      }
                      .pp {
                        span {
                          img {
                            width: 70px;
                            margin-#{$ldirection}: 10px;
                          }
                        }
                      }
                      .afterpay-option {
                        @include swap_direction(padding, 20px 0);
                        width: 100%;
                        border-bottom: 1px solid #{$color-black};
                        margin-bottom: 20px;
                        .afterpay-image {
                          @include swap_direction(margin, 0 3px);
                          width: 114px;
                        }
                        .afterpay {
                          @include swap_direction(margin, 14px 0 0 26px);
                          width: 90%;
                          display: block;
                        }
                        .afterpay-disclaimer {
                          display: block;
                          margin-top: 10px;
                        }
                      }
                      input {
                        &.btn {
                          &.afterpay-button {
                            text-align: #{$ldirection};
                            background: #{$color-black} url(/media/images/checkout/cp-logo-white-transparent.svg) no-repeat
                              68% 49%;
                            width: 100%;
                            padding-#{$ldirection}: 17%;
                            @include breakpoint($iphone6plus-up) {
                              padding-#{$ldirection}: 25%;
                              background-position: 60% 49%;
                            }
                            @include breakpoint($xsmall-up) {
                              padding-#{$ldirection}: 18%;
                              background-position: 66% 49%;
                            }
                            @include breakpoint($xxsmall-down) {
                              padding-#{$ldirection}: 18%;
                              background-position: 72% 49%;
                            }
                          }
                        }
                      }
                      .afterpay-learnmore {
                        .info-icon {
                          width: 21px;
                          height: 20px;
                          margin-#{$ldirection}: 2px;
                          display: inline-block;
                          cursor: pointer;
                        }
                      }
                    }
                  }
                }
              }
            }
            .checkout__sidebar {
              .checkout-panel--need-help {
                .need-help-content {
                  .need-help-content__item {
                    margin-bottom: 5%;
                  }
                }
              }
              #shipmethod-panel {
                .content {
                  .ship-method-group-label {
                    margin-bottom: 1em;
                    width: 100%;
                    .shipment-option {
                      margin: 0.5em;
                    }
                  }
                  select {
                    width: 100%;
                  }
                }
              }
              #order-summary-panel {
                .checkout-panel--viewcart-buttons {
                  .total-container {
                    .total-container__content {
                      .total {
                        &.label {
                          text-align: left;
                          width: 50%;
                          display: inline-block;
                          font-weight: bold;
                        }
                        &.value {
                          float: right;
                          text-align: left;
                          display: inline-block;
                          font-weight: bold;
                        }
                      }
                    }
                  }
                  .afterpay {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#confirm {
  .checkout--confirmation-page {
    .registration-content {
      width: 100%;
      &__create-account {
        display: block;
        .pre {
          white-space: pre-line;
        }
      }
      .registration-content__create-account {
        h3 {
          font-size: 18px;
        }
        .example-user-email {
          margin: 0 0 10px 0px;
          display: block;
        }
        .registration-form-container,
        .divide {
          margin-bottom: 15px;
        }
        .promotions-container {
          .email_promotions {
            .sms-mobile {
              .sms-mobile-phone {
                margin: 23px 0 0 -7px;
                padding: 0;
                overflow: inherit;
                text-align: left;
                .sms_input {
                  width: 100%;
                }
                label.sms_label:after {
                  display: none;
                }
              }
              &-prefix {
                display: inline-block;
                margin-right: 4%;
                margin-top: 23px;
              }
            }
          }
        }
      }
    }
    .checkout-panel--sign-in-confirmation {
      input {
        width: 100%;
      }
      .form-item {
        margin: 5% 0;
        .email-address {
          label {
            font-weight: bold;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    .checkout-panel--confirmation {
      div.print-buttons {
        display: block;
        margin-top: 10px;
      }
    }
  }
}

.click_and_collect_map {
  .overlay-content {
    .address-map-search-panel {
      background: none !important;
      .search-box {
        width: 100% !important;
        .search-box-field {
          width: 230px !important;
          font-size: 14px !important;
        }
        .search-box-submit {
          margin: -2px 0 0 -11px;
        }
      }
      .search-box-geolocate {
        display: none;
      }
    }
    .map-side-tools {
      bottom: 10% !important;
    }
  }
}

.samples-page {
  .checkout-panel--samples {
    .checkout-panel__content {
      .samples {
        .offer {
          ul.product-list {
            li.product {
              min-height: 320px;
              position: relative;
              .details {
                height: 70px;
              }
              ul.skus {
                li.sku {
                  text-align: center;
                  border: 1px solid $color-black;
                  cursor: pointer;
                  margin-top: 20px;
                }
              }
              a.sample-select-button {
                text-decoration: none;
                padding: 0;
                background: transparent;
                border: 0;
                line-height: 17px;
                width: auto;
                outline: 0;
                position: relative;
              }
              &.selected {
                ul.skus {
                  li.sku {
                    background: $color-black;
                    a {
                      background: $color-black;
                      color: $color-white;
                    }
                  }
                }
              }
              &.deactivate {
                ul.skus {
                  li.sku {
                    background: transparent;
                    cursor: default;
                    opacity: 0.3;
                    a {
                      background: transparent;
                      color: $color-black;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .continue-shopping {
      &__container--outer {
        .continue-shopping__button {
          width: 100%;
        }
      }
      &__container--inner {
        .continue-buttons {
          margin: 2em 0;
        }
        .js-continue-checkout {
          margin: 2em 0;
        }
      }
    }
  }
}

/*** ApplePay integration ***/

/* User Not signed in */

.viewcart-buttons-panel {
  &.applepay {
    .continue-shopping {
      position: static;
    }
    .checkout-buttons-content__container {
      &.applepay-enabled {
        margin-bottom: 20px;
        .continue-checkout,
        .paypal-checkout {
          float: none !important;
          width: 100% !important;
        }
        .continue-checkout {
          margin-bottom: 7px !important;
        }
        .or {
          display: block;
          margin: 10px 0 0;
          width: 100% !important;
          text-align: center;
        }
      }
    }
    .applepay-integration-wrapper {
      display: table;
      table-layout: fixed;
      clear: both;
      width: 100%;
      float: none;
      margin: 0;
      padding: 10px 13px;
      border: 1px solid #b7b7b7;
      border-spacing: 10px;
      background: #ebebeb;
      &.applepay-error {
        border-color: #e61f26;
        background-color: #ffe2e4;
      }
      .applepay-button-container {
        text-align: left;
        .apple-pay-button {
          display: block;
          overflow: hidden;
          text-indent: 100%;
          white-space: nowrap;
          -webkit-appearance: -apple-pay-button;
          -apple-pay-button-style: black;
        }
        .apple-pay-button.button-style-buy {
          -apple-pay-button-type: buy;
        }
        .apple-pay-button.button-style-set-up {
          -apple-pay-button-type: set-up;
        }
      }
      div.applepay-button-container {
        display: table-footer-group;
        float: none;
        width: auto;
        text-align: justify;
        .divide {
          position: relative;
          padding-right: 20px;
          input[name='ACCEPTED_PRIVACY_POLICY'] {
            left: initial;
            position: absolute;
            top: calc(50% - 7px);
            right: 0;
            width: 15px;
            height: 15px;
          }
          label.terms_input {
            &:before {
              content: none;
              position: absolute;
              top: calc(70% - 7px);
              right: 10px;
              width: 15px;
              height: 15px;
              border: 1px solid #040a2b;
            }
            span.label-content {
              margin: 0;
            }
          }
        }
      }
      span.applepay-button-container {
        display: table-header-group;
        float: none;
        width: auto;
      }
    }
  }
}
/* User Signed in */

.elc-user-state-logged-in {
  .viewcart-buttons-panel {
    &.applepay {
      .applepay-integration-wrapper {
        padding: 0;
        border: 0 none;
        background: transparent;
        border-spacing: 0;
        .applepay-button-container {
          .apple-pay-button {
            height: 35px;
          }
        }
      }
    }
  }
}
/* Hide error message from first container */
.top-viewcart-buttons {
  .js-applepay-error-container {
    display: none;
  }
}

/*** END of ApplePay integration ***/
